import React from 'react';
import { Link } from "react-router-dom";
import './Widget.style.scss'

function Widget(props) {

    const {_id, title, subtitle, icon, application, picture, status} = props.config;
    
    return (
        <>
            {picture ? 
                <Link to={`../../${application}/${_id}`} className= {"profile"}>
                    <img src={picture} alt="" />
                    <div className= {"widget " + status}>
                        <span className="widget__icon icon light">&nbsp;</span>
                        <span className="widget__subtitle">{subtitle}</span>
                        <span className="widget__title">{title}</span>  
                    </div>               
                </Link>
                :
                <Link to={`../../${application}/${_id}`} className= {"widget " + (status?status:"")}>
                    <span className="widget__icon icon light">{icon}</span>
                    <span className="widget__subtitle">{subtitle}</span>
                    <span className="widget__title">{title}</span>             
                </Link>
            } 
        </>
    )
}

export default Widget;