import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useParams, useHistory, Routes, Link, Redirect} from "react-router-dom";
import Nav from '../common/Nav';
import graphqlApi from '../../api/graphqlApi';
import './SetScore.style.scss';


class SetScoreForm extends React.Component {
    constructor(props) {
      super(props);
      this.title = "Set Score";
      this.navConfig = {
          "url":"/SetScore"
      };
      this.state = {senderId: '', score: ''};
  
      this.senderHandleChange = this.senderHandleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.scoreHandleChange = this.scoreHandleChange.bind(this);
    }
  
    senderHandleChange(event) {
      this.setState({senderId: event.target.value});
    }
    scoreHandleChange(event) {
        this.setState({score: event.target.value});
    }
  
    handleSubmit(event) {
        const mutationQuery = JSON.stringify(
            {
                query: `mutation setScore($scoreProcessing: ScoreInput!) {
                    setScore(scoreProcessing: $scoreProcessing) {
                        success
                        errors
                        senderId
                        score
                    }
                }`,
                variables: {
                    "scoreProcessing": {
                        "senderId": this.state.senderId,
                        "score": parseFloat(this.state.score)
                    }
                }
            }
        );
        graphqlApi(mutationQuery).then(
            result => {
                console.log(result);
                if (result.data !== null) {
                    if (result.data.setScore.success) {
                        alert('Score seted successfully.');
                    } else {
                        alert('Something went wrong.');
                    }} else {
                        alert('Enter senderId and score rate.');
                    }
            }
        ).catch(err => {console.log(err)});
      event.preventDefault();
    }
  
    render() {
      return (
        <>
        <div>
            <div className='setScoreContainer'>
                <form onSubmit={this.handleSubmit}>
                    <label className='SetScoreLabels' htmlFor="senderId">Enter senderId</label>
                    <input id="senderId" name="senderId" type="text" value={this.state.senderId} onChange={this.senderHandleChange} />
                    <label className='SetScoreLabels' htmlFor="score">Enter minimal score rate</label>
                    <input id="score" name="score" type="text" value={this.state.score} onChange={this.scoreHandleChange} />
                    <div>
                        <div className='scoreInputs'>
                        <input className='inputButtons' type="submit" value="Set score"/>
                        </div>
                        <div className='scoreInputs'>
                        <Link className='inputButtons' to="/Actions">Back</Link>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        </>
      );
    }
  }
export default SetScoreForm;