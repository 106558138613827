import { createSlice } from '@reduxjs/toolkit';
import { accounts } from "@azure/msal-react";

import { loggedUser } from "../api/dummyData"

export const userSlice = createSlice({
  name: 'auth',
  initialState: {
    user: loggedUser.user,
    token: loggedUser.token
  },
  reducers: {
    enterUser: (state, action) => {
      state.user = action.payload.user;
      console.log("state.user: ", state.user);
      state.token = action.payload.token;
      console.log("state.token: ", state.token);
    },
    updateToken: (state, action) => {
      state.token = action.payload.token;
    }
  },
});

export const { enterUser, updateToken } = userSlice.actions;

export const selectUser = state => state.auth.user;
export const selectToken = state => state.auth.token;

export default userSlice.reducer;
