import React, {useEffect} from 'react';
import { useParams, useHistory } from "react-router-dom";
import Nav from '../common/Nav';

import './AccessManagement.style.scss';

function AccessManagement() {
    const title = "Access Management";
    let { userName } = useParams();
    const history = useHistory();

    const getUser = (username) => {
        if(username.match(/^.{3,20}$/)){

        }
    };

    const navConfig = { "url":`/User/Profile/${userName}` };
    
    useEffect(() => {
        getUser(userName);
    },[]);
    
    return (
        <>
            <Nav title={title} config={navConfig} />
            <div className="container">
                <div className="container__full">
                    <div className="form">
                        <span>Organizational role</span>
                        <select name="" id="">
                            <option value=""></option>
                            <option value="administrator">Administrator</option>
                            <option value="moderator">Moderator</option>
                            <option value="visitor">Visitor</option>
                        </select>
                    </div>
                    <hr />
                    <div className="form">
                        <span>Groups</span>
                    </div>
                </div>
            </div>
            <div className="bottomBar">
                <button className="block" onClick={() => {history.goBack()}}>Save</button>
            </div>
        </>
    )
}

export default AccessManagement;