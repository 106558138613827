import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
// import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { selectUser, enterUser } from '../../reducers/userSlice';
import './Header.style.scss';
import Logo from '../../assets/images/logo.svg';
import Sidebar from "./Sidebar";
import { useMsal } from '@azure/msal-react';

function Header() {
    const { instance } = useMsal();
    const auth = useSelector(selectUser);
    const history = useHistory();
    const dispatch = useDispatch();

    const logout = () => {
        dispatch(
            enterUser({
                user: null,
                token: null
            })
        );
        history.push("/");
    };

    const openNav = () => {
        document.querySelector('#sidebar').classList.add('active');
        document.querySelector('div.sidenav').classList.add('active');
    };

    const closeNav = () => {
        document.querySelector('#sidebar').classList.remove('active');
        document.querySelector('div.sidenav').classList.remove('active');
    };

    useEffect(() => {
        // Update the document title using the browser API
        console.log('calling header page')
    });    

    return (
        <>
            <Sidebar auth={auth} closeNav={closeNav} />
            <header className="header">
                <span className="header__menu icon bold" onClick={openNav}>menu</span>
                <img src={Logo} alt="" className="header__logo"/>
                <div className="header__actions">
                    <span className="header__icon icon bold" onClick={() => instance.logout()}>power</span>
                    <span className="header__icon icon bold">bell</span>
                </div>
            </header>
        </>
    )
}

export default Header;