import React from 'react';
import './Loading.style.scss';

function Loading() {  
  return (
    <div id="loading" className="loading">
        <div className="loadin__center">
            <div className="loading__center__dots">
                <div className="loading__center__dots__circle"/>
                <div className="loading__center__dots__circle"/>
                <div className="loading__center__dots__circle"/>
            </div>
            <div className="loading__center__title">Loading ...</div>
        </div>
    </div>
  );
}

export default Loading;
