export const demoWidgets = [
    {
        _id: "Actions",
        title: "Actions",
        subtitle: "Pictures",
        icon: "cloud_upload",
        application: "Actions",
        picture: null,
        status: null
    },
    {
        _id: "Security",
        title: "Security",
        subtitle: "Settings ",
        icon: "shield",
        application: "Empty",
        picture: null,
        status: null
    }
];

export const listOfWidgets = [
    {
        _id: "alesmi",
        title: "User Management",
        subtitle: " ",
        icon: "users",
        application: "UserManagement/Users",
        picture: null,
        status: null
    }
];

// ----------------------------------------------------------------

export const loggedUser = {
    "user": {
        "userName": "bjonos",
        "role": "Administrator",
        "email": "bjorn.nostdahl@gunnebo.com",
        "phone": null,
        "fullName": "Bjørn Tore Nøstdahl",
        "mfaEnabled": false
    },
    "token": {
        "id": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6ImFsZXNtaSIsInJvbGUiOiJBZG1pbmlzdHJhdG9yIiwibmJmIjoxNjI0MzA4MDYxLCJleHAiOjE2MjQzMDk4NjEsImlhdCI6MTYyNDMwODA2MX0.rjvIzqAPLCeC-2TNQTWlyS_g3iA6Jj1aAugVmdtlcqk",
        "expiresAt": "2021-06-21T21:11:01.306Z"
    }
};

// ----------------------------------------------------------------

export const profileUser = {
    "groupNames": [
        "Gunnebo Italy",
        "Gunnebo Test Group"
    ],
    "userName": "bjonos",
    "email": "bjorn.nostdahl@gunnebo.com",
    "firstName": "Bjørn Tore",
    "middleName": "",
    "lastName": "Nøstdahl",
    "isActive": true,
    "role": "Administrator",
    "changePasswordOnFirstLogon": false
};

// ----------------------------------------------------------------

export const workordersWiKey = {
    "workOrders": []
};

// ----------------------------------------------------------------

export const locksWiKey = {
    "locks": []
};

// ----------------------------------------------------------------

export const accountsWiKey = {
    "users": []
};

// ----------------------------------------------------------------

export const sitesUtilities = {
    "sites": []
};

// ----------------------------------------------------------------

export const eventsReports = [];