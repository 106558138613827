import React, { useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import './Nav.style.scss';

function Nav({title,config}) {
    const history = useHistory();
    const [modal, setModal] = useState("");

    const navigation = (e) => {
        var navMenu = document.getElementById("navMenu");
        if(e.target.id !=="navDots"){
            try {navMenu.style.display = "none";}
            catch(e) {}
        }
        else {
            navMenu.style.display = "block";
        }
    };

    document.addEventListener("click",navigation);
    
    const itemClick = (item) => {
        let renderModal = "";
        if(item.type === "confirm") 
            renderModal = (<div className="modal__confirm">
                <h3>{item.title}</h3>
                <p>{config.message}</p>
                <div className="modal__footer">
                    <button onClick={() => {closeModal()}}>No</button>
                    <button onClick={() => {history.push("/")}}>Yes</button>
                </div>
            </div>);
        setModal(renderModal);
        document.getElementById("modal").style.display = "flex";
    };

    const closeModal = () => {
        document.getElementById("modal").style.display = "none";
    };

    const listItem = (item, index) => {
        let renderItem = "";
        if(item.type === "link") renderItem = <li key={index} className="nav__menu__item"><Link to={item.link}>{item.title}</Link></li>;
        if(item.type === "button") renderItem = <li key={index} className="nav__menu__item"><Link to="?" onClick={() => itemClick(item.config)}>{item.title}</Link></li>;
        return renderItem;
    };

    return (
        <div className="nav">
            <Link to={config.url?config.url:"/"}  className="nav__back icon bold">chevron_left</Link>
            <span className="nav__title">{title}</span>
            {config.menu && <span className="nav__dots icon bold" id="navDots">more_alt</span> }
            {config.menu && <ul className="nav__menu" id="navMenu">
                {config.menu.map((item, index) => listItem(item, index))}
            </ul>}
            <div id="modal" className="modal">{modal}</div>
        </div>
    )
}

export default Nav;