import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../reducers/userSlice';
import Widget from './Widget';
import { demoWidgets, listOfWidgets} from '../../api/dummyData';
import axios from "axios";
import './Dashboard.style.scss';

import alesmi from "../../assets/images/alesmi.jpg";
import bjonos from "../../assets/images/bjonos.jpg";
import vitalina from "../../assets/images/vitalina.jpg";
import samsal2 from "../../assets/images/samsal2.jpg";
import genericUser from "../../assets/images/user.jpg";

function Dashboard() {
    const auth = useSelector(selectUser);

    const user = {
        _id: `Profile/${auth.userName}`,
        title: auth.fullName,
        subtitle: auth.role,
        icon: "user",
        application: "User",
        picture: null,
        status: null
    };

    if(auth.userName === "alesmi") user.picture = alesmi;
    else if(auth.userName === "bjonos") user.picture = bjonos;
    else if(auth.userName === "vitryb") user.picture = vitalina;
    else if(auth.userName === "samsal2") user.picture = samsal2;
    else user.picture = genericUser;

    const [widgets, setWidgets] = useState([]);
    
    const getWidgets = async () => {
        (auth.userName === "alesmi")?setWidgets(listOfWidgets):setWidgets(demoWidgets);
    };

    useEffect(() => { getWidgets();}
    ,[widgets]);

    return (
        <>
            <div className="listOfWidgets">
                <Widget key={user._id} config={user} />
                { widgets.map((data, index) => <Widget key={index} config={data} />) }
                <div className="fullWidth">&nbsp;</div>
            </div>
        </>
    )
}

export default Dashboard;