import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useParams, useHistory } from "react-router-dom";
import Nav from '../common/Nav';
import graphqlApi from '../../api/graphqlApi';
import Message from './Message';
import './Actions.style.scss';

const Actions = () => {
    const title = "Actions";
    const history = useHistory();
    const navConfig = {
        "url":"/",
        "menu": [
            {"link":`/Actions/NewAction`,"title":"New action", "type":"link", "config": null},
            {"link":`/Actions/SetScore`,"title":"Set score", "type":"link", "config": null}
        ]
    };

    let { id } = useParams();
    const [ messages, setMessages ] = useState([]);
    const [ senderId, setSenderId ] = useState();
    const [ wsConn, setWsConn ] = useState(undefined);

    const [ sampleId, setSampleId ] = useState(String);
    const [ sampleStatus, setSampleStatus ] = useState(String);
    const [ messagesList, setMessagesList ] = useState([]);
    const [ messagesScoreList, setMessagesScoreList ] = useState([]);
    const [ minimalSetedScore, setMinimalSetedScore ] = useState(String);
    const [ timestamp, setTimestamp ] = useState(String);

    //const [ statusBaner, setStatusBaner ] = useState();
    
    const [ listening, setListening ] = useState(false);


    useEffect(() => {
        if(listening){
            setWsConn(createSubscriptionCall());
            const timer = setInterval(() => {
                get_last_sample();
                if(!listening )clearInterval(timer);
            }, 2000);
            return () => clearInterval(timer);
        } else {
            //setStatusBaner(String("Disconnected"));
            wsConn && wsConn.close();
            setMessages([]);
            setMessagesList([]);
            setMessagesScoreList([]);
        }
    },[listening]);

    const checkBaner = (messagesScoreList) => {
        let i = 0;
        if (messagesScoreList.length > 0) {
        while (i < messagesScoreList.length) {
            console.log(i);
            if (minimalSetedScore > messagesScoreList[i]) {
                //setStatusBaner(String("ERROR"));
                console.log("error: " + messagesScoreList[i]);
                break; 
            } else {
                //setStatusBaner(String("OK"));
                console.log("ok: " + messagesScoreList[i]);
            }
            i++;
            //console.log("status baner:", statusBaner);
        }
    } else {console.log("score list is empty")}
    };

    const get_last_sample = () => {
        const queryRequest = JSON.stringify({
            query: `query getLastSample($getSampleStatusesBySenderId: String!) {
                getLastSample(filter: $getSampleStatusesBySenderId) {
                    sample {
                        id
                        senderId
                        sample
                        sourceType
                        status
                        minimalSetedScore
                        timestamp
                    }
                    count
                    success
                    errors
                }
            }`,
            variables: {
                "getSampleStatusesBySenderId": String(senderId)
            }
        });

        graphqlApi(queryRequest)
        .then(result => {
            console.log(result.data.getLastSample.sample);
            setMessages(result.data.getLastSample.sample);
            setSampleId(result.data.getLastSample.sample[0].id);
            setSampleStatus(result.data.getLastSample.sample[0].status);
            setMinimalSetedScore(result.data.getLastSample.sample[0].minimalSetedScore);
            setTimestamp(result.data.getLastSample.sample[0].timestamp);
            console.log("result.data.getLastSample.sample[0].minimalSetedScore:", result.data.getLastSample.sample[0].minimalSetedScore)
            if (result.data.getLastSample.sample[0].status === "done") {
                get_sample_status(result.data.getLastSample.sample[0].id);
            } else {
                setMessagesList([]);
                setMessagesScoreList([]);
            }
        })
        .catch(err => { console.log(err) });
    };

    const get_sample_status = (Id) => {
        const queryRequest = JSON.stringify({
            query: `query sampleStatus($sample_id: String!) {
              sampleStatus(id: $sample_id) {
                    status {
                        sampleId
                        senderId
                        message
                        status
                    }
                    success
                    errors
                }
            }`,
            variables: {
                "sample_id": String(Id)
            }
        });
        graphqlApi(queryRequest).then(
            result => {
                const ssMessages = JSON.parse(result.data.sampleStatus.status.message);
                setMessagesList(ssMessages[0].boxes.map(box => <li>{box.label}</li>));
                setMessagesScoreList(ssMessages[0].boxes.map(box => <li>{String(box.score).substring(0, 4)}</li>));
                
                //checkBaner(ssMessages.boxes.map(box => box.score));
            }
        ).catch(err => {console.log(err)});
    };

    const createSubscriptionCall = () => {
        var request = JSON.stringify({
            id: 1,
            type: "start",
            payload: {
                query: `subscription subscribeSample($sampleSubscriptionInput: SampleSubscriptionInput!) {
                        subscribeSample(sampleSubscription: $sampleSubscriptionInput) {
                            id
                            senderId
                            status
                            sample
                            sourceType
                        }
                    }`,
                variables: {
                    "sampleSubscriptionInput": {
                        "senderId": String(senderId)
                    }
                },
                operationName:"subscribeSample"
            }
        })

        let exampleSocket = new WebSocket("wss://viasgraphql.azurewebsites.net/graphql", ["graphql-ws"]);

        exampleSocket.onopen = (event) => {
            console.log(event);
            console.log("opened connection")
            exampleSocket.send(JSON.stringify({type: "connection_init", payload: {}}));
            exampleSocket.send(request);
            console.log("Listening...  for sender id : " + senderId);
        };

        exampleSocket.onerror = (event) => {
            console.log("Error while connecting...");
            console.table(event);
        }

        exampleSocket.onclose = (event) => {
            console.log(event);
            console.log("Close connection");
            setListening(false);
        }

        return exampleSocket;
    };

    return (
        <>
        <Nav title={title} config={navConfig} />
        <div className="container">
            <div className="container__full">
                <form className="infoForm">
                    <div className="contentContainer">
                    <div className="sampleContainer">
                        {messages && messages.slice(-1).map(message => Message(message))}
                    </div>
                    <div className='messagesContainer'>
                        <span>Sender Id</span>
                        <input type="text" value={senderId} onChange={(e) => setSenderId(e.target.value)} disabled={listening} />
                        <div className="checkbox">
                        <span>Listen messages</span>  
                        <label className="switch">
                            <input type="checkbox" defaultChecked={listening} onClick={() => setListening(!listening)} />
                            <span className="slider round"></span>
                        </label>
                        </div>
                        Sample Id: {sampleId} <br /> 
                        Sender Id: {senderId} <br />
                        Score rate: {minimalSetedScore} <br />
                        Timestamp: {timestamp} <br />
                        Status: {sampleStatus} <br />
                        <div className="messageColumn">
                        Information:<br />{messagesList}
                        </div>
                        <div className="scoreColumn">
                            Score:<br /> {messagesScoreList}
                            </div>
                    </div>
                    </div>
                </form>
            </div>
        </div>
        </>
    )
};

export default Actions;