import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import store from './stores/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './authConfig';
import { MsalProvider } from '@azure/msal-react';

const msalInstance = new PublicClientApplication(msalConfig)

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
    <Provider store={store}>
      <App />
    </Provider>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
serviceWorker.unregister();