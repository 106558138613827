import { LogLevel } from "@azure/msal-browser"

export const b2cPolicies = {
    names: {
        signIn: "B2C_1_GunneboSafeStorage",
        //signUpSignIn: "B2C_1_GunneboSafeStorage",
        forgotPassword: "B2C_1_ForgotPassword",
        editProfile: "b2c_1_edit_profile"
    },
    authorities: {
        signIn: {
            authority: "https://GunneboSafeStorage.b2clogin.com/GunneboSafeStorage.onmicrosoft.com/B2C_1_GunneboSafeStorage",
        },
        signUpSignIn: {
            authority: "https://GunneboSafeStorage.b2clogin.com/GunneboSafeStorage.onmicrosoft.com/B2C_1_GunneboSafeStorage",
        },
        forgotPassword: {
            authority: "https://GunneboSafeStorage.b2clogin.com/GunneboSafeStorage.onmicrosoft.com/B2C_1_ForgotPassword",
        },
        editProfile: {
           authority: "https://GunneboSafeStorage.b2clogin.com/GunneboSafeStorage.onmicrosoft.com/b2c_1_edit_profile"
        }
    },
    authorityDomain: "GunneboSafeStorage.b2clogin.com"
}

/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */
export const msalConfig = {
    auth: {
        clientId: "7963d72a-aa07-4e75-bfb8-2f8a31f2201d", // This is the ONLY mandatory field that you need to supply.
        authority: b2cPolicies.authorities.signIn.authority, // Use a sign-up/sign-in user-flow as a default authority
        knownAuthorities: [b2cPolicies.authorityDomain, "GunneboSafeStorage.b2clogin.com"], // Mark your B2C tenant's domain as trusted.
        redirectUri: "https://vias.gunnebo.cloud", // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
        postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {	
        loggerOptions: {	
            loggerCallback: (level, message, containsPii) => {	
                if (containsPii) {		
                    return;		
                }		
                switch (level) {		
                    case LogLevel.Error:		
                        console.error(message);		
                        return;		
                    case LogLevel.Info:		
                        console.info(message);		
                        return;		
                    case LogLevel.Verbose:		
                        console.debug(message);		
                        return;		
                    case LogLevel.Warning:		
                        console.warn(message);		
                        return;		
                }	
            }	
        }	
    }
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit: 
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
    scopes: ["openid", "profile"],
    loginHint: "example@domain.net"
};

/**
 * An optional silentRequest object can be used to achieve silent SSO
 * between applications by providing a "login_hint" property.
 */
const silentRequest = {
  scopes: ["openid", "profile"],
  loginHint: "example@domain.net"
};