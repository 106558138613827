
const graphqlApi = (queryRequest) => new Promise((resolve, reject) => {
    console.log(process.env.REACT_APP_GRAPHQL_SERVER)
    try {
        fetch(`${process.env.REACT_APP_GRAPHQL_SERVER}/graphql`, {
        method: "POST",
        body: queryRequest,
        headers: {"Content-type": "application/json; charset=UTF-8", "Access-Control-Allow-Origin": "*", "Access-Control-Allow-Methods":"*"}
        })
        .then(response => response.json()) 
        .then(json => resolve(json))
        .catch(err => reject(err));
    } catch (error) {
        reject(error);
    }
});
  
export default graphqlApi;
