import React, {useEffect, useState, useRef } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import { selectToken } from '../../reducers/userSlice';
import { profileUser } from '../../api/dummyData';
import Nav from '../common/Nav';

import alesmi from "../../assets/images/alesmi.jpg";
import bjonos from "../../assets/images/bjonos.jpg";
import mrbean from "../../assets/images/mrbean.jpg";

import './Profile.style.scss';

function Profile() {
    const title = "Profile";
    let { userName } = useParams();
    const [picture, setPicture] = useState("");
    const token = useSelector(selectToken);
    const history = useHistory();

    const firstNameRef = useRef("");
    const middleNameRef = useRef("");
    const lastNameRef = useRef("");
    const emailRef = useRef("");

    const getUser = (username) => {

        if(userName === "alesmi") setPicture(alesmi);
        else if(userName === "bjonos") setPicture(bjonos);
        else setPicture(mrbean);
        if(username.match(/^.{3,20}$/)){
            console.log(profileUser);

            firstNameRef.current.value = profileUser.firstName||"";
            middleNameRef.current.value = profileUser.middleName||"";
            lastNameRef.current.value = profileUser.lastName||"";
            emailRef.current.value = profileUser.email||"";
        }
    };

    const saveUser = () => {
        const data = new Map();
        data.set("firstName",firstNameRef.current.value);
        data.set("middleName",middleNameRef.current.value);
        data.set("lastName",lastNameRef.current.value);
        if(emailRef.current.value !== "") data.set("email",emailRef.current.value);
        
        history.push("/");
    };

    const navConfig = {
        "url":"/",
        "menu": [
            {"link":`../../User/AccessManagement/${userName}`,"title":"Access management", "type":"link", "config": null},
            {"link": null,"title":"Delete user", "type":"button", "config": {"type":"confirm","title":"Delete user","message":"Are you sure?","action":`/users/${userName}`,"methond":"delete"}},
        ]
    };

    useEffect(() => {
        getUser(userName);
    },[]);

    return (
        <>
            <Nav title={title} config={navConfig} />
            <div className="container">
                <div className="container__full">
                    <div className="profilePicture">
                        <img alt="" src={picture}/>
                    </div>
                    <div className="form">
                        <span>First name</span>
                        <input type="text" ref={firstNameRef} />
                        <span>Middle name</span>
                        <input type="text" ref={middleNameRef} />
                        <span>Last name</span>
                        <input type="text" ref={lastNameRef} />
                        <span>Email</span>
                        <input type="email" ref={emailRef} />
                    </div>
                </div>
            </div>
            <div className="bottomBar">
                <button className="block" onClick={() => saveUser()}>Save</button>
            </div>
        </>
    )
}

export default Profile;