import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import {enterUser, selectUser, updateToken } from './reducers/userSlice';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { EventType, InteractionType, InteractionStatus } from '@azure/msal-browser';
import { b2cPolicies, loginRequest, silentRequest } from './authConfig';
import Header from './components/common/Header';
import Dashboard from "./components/common/Dashboard.js"; 
import Profile from './components/User/Profile';
import AccessManagement from './components/User/AccessManagement';
import Loading from './components/common/Loading';
import Empty from './components/Empty/Application';
import Actions from './components/Actions/Actions';
import NewAction from './components/Actions/NewAction';
import SetScore  from './components/Actions/SetScore';

function App() {
  const dispatch = useDispatch()
  const userName = useSelector(selectUser);
  const { instance, accounts, inProgress } = useMsal();
  

  useEffect(() => {
    const callbackId = instance.addEventCallback((event) => {
      console.log("events ", event)
      console.log("event.eventType", event.eventType)
      if (event.error && event.error.errorMessage.indexOf("AADB2C90118") > -1) {
        if (event.interactionType === InteractionType.Redirect) {
          instance.loginRedirect(b2cPolicies.authorities.forgotPassword);
        } else if (event.interactionType === InteractionType.Popup) {
          instance.loginPopup(b2cPolicies.authorities.forgotPassword)
            .cache(e => {
              return;
            })
        }
      }

      if (event.eventType === EventType.LOGIN_SUCCESS) {
        if (event?.payload) {
          console.log("#####event test page...", event.payload)
          dispatch(enterUser({
            user: {
              "userName": event.payload.account.localAccountId,
              "role": "Administrator",
              "fullName": event.payload.account.name,
              "email": ""
            },
            token: event.payload.idToken
          }))
          if (event.payload.idTokenClaims["acr"] === b2cPolicies.names.forgotPassword) {
            window.alert("Password has been reset successfully. \nPlease sign-in with your new password");
            return instance.logout();
          }
        }
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, []);

  useEffect( async () => {
    console.log("account updated ", accounts.length);
    

    if (accounts.length > 0) {
      const account = accounts[0]
      dispatch(enterUser({
        user: {
          "userName": account.localAccountId,
          "role": "Administrator",
          "fullName": account.name,
          "email": ""
        },
        token: null
      }))

      const request = {
        account: account,
        scopes: []
      }

      await instance.handleRedirectPromise();
      instance.acquireTokenSilent(request).then(token => {
        dispatch(updateToken({
          token: token
        }))
      });
    }
  }, [accounts])

  return (
      <Router>
        {<AuthenticatedTemplate>
          {console.log("loggined")}
          {console.log("testing.......," , accounts)}
          <div className="interface">
                <Header />
                <Switch>
                    <Route path="/User/AccessManagement/:userName" children={<AccessManagement />} />
                    <Route path="/User/Profile/:userName" children={<Profile />} />
                    <Route path="/Actions/NewAction" children={<NewAction />} />
                    <Route path="/Actions/SetScore" children={<SetScore />} />
                    <Route path="/Actions" children={<Actions />} />
                    <Route path="/Empty" children={<Empty />} />
                    <Route path="/" strict>
                    <Dashboard />
                    </Route>
                </Switch>
                <Loading />
            </div>
        </AuthenticatedTemplate>}

        {<UnauthenticatedTemplate>
          <h5 className="card-title">Please sign-in to see your profile information.</h5>
          <button onClick={() => instance.loginRedirect(loginRequest)}>Sign In</button>
          {console.log("not loggined")}

        </UnauthenticatedTemplate>}

      </Router>
  );
}

export default App;
