import React, {useEffect, useState, useRef } from 'react';
import Nav from '../common/Nav';

import './Application.style.scss';

function Application() {
    const title = "Empty";
    const searchRef = useRef("");
    const [customFilter, setCustomFilter] = useState("");

    const getUsers = () => {
        console.log("getUsers");
    };

    const filterUsers = () => {
        setCustomFilter(searchRef.current.value.toLocaleLowerCase());
    };
    
    const navConfig = { "url":"/" };

    useEffect(() => {
        searchRef.current.value = "";
        getUsers();
    },[]);

    const users = [];

    return (
        <>
            <Nav title={title} config={navConfig} />
            <div className="container">
                <div className="container__full">
                    <div className="container__full__search">
                        <input type="text" placeholder="Search" ref={searchRef} onChange={filterUsers} />
                        <span className="icon bold">zoom</span>
                    </div>
                    <div className="usersList">
                    </div>
                </div>
            </div>
        </>
    )
}

export default Application;