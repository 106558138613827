import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useParams, useHistory } from "react-router-dom";
import Nav from '../common/Nav';
import graphqlApi from '../../api/graphqlApi';

import './NewAction.style.scss';

const NewAction = () => {
    const title = "New Action";
    const history = useHistory();
    const navConfig = {
        "url":"/Actions"
    };

    let { id } = useParams();

    const [ imageBase64, setImageBase64 ] = useState(null);
    const [ senderId, setSenderId ] = useState(1);

    const resetForm = () => {
        document.getElementById("sendForm").reset();
        setImageBase64(null);
    };

    const updateFile = (files) => {
        setImageBase64(null);
        const fileInput = document.querySelector('input[type="file"]').files[0];
        var reader = new FileReader();
        reader.readAsDataURL(fileInput);
        reader.onload = function () {
            setImageBase64(reader.result);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    };

    const sendMessage = () => {
        const mutationQuery = JSON.stringify({
            query: `mutation sendSample($sendSampleInput: SampleInput!) {
              sendSample(sample: $sendSampleInput) {
                sample {
                  id
                  senderId
                  sample
                  sourceType
                }
                success
                errors
              }
            }`,
            variables: {
                "sendSampleInput": {
                    "id": "dummy",
                    "senderId": String(senderId),
                    "sample": imageBase64,
                    "sourceType": "embedded"
                  }
            }
        });
        graphqlApi(mutationQuery)
        .then(value => { history.goBack() })
        .catch(err => { console.log(err) });
    };

    return (
        <>
        <Nav title={title} config={navConfig} />
        <div className="container">
            <div className="container__full">
                <form className="form" id="sendForm">
                    <span>Sender Id</span>
                    <input type="text" defaultValue={senderId} onChange={(e) => setSenderId(e.target.value)} />
                    <span>Select image</span>
                    <input type="file" onChange={() => updateFile()}/>
                    <br /><hr /><br />
                    {imageBase64 && <img id="preview" className='preview' src={imageBase64} />}
                </form>
            </div>
        </div>
        <div className="bottomBar">
            <button className="block" onClick={() => resetForm()}>Reset</button>
            <button className="block primary" disabled={imageBase64 === null} onClick={() => sendMessage()}>Send</button>
        </div>
        </>
    )
};

export default NewAction;