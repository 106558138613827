import React, { useEffect } from 'react';
// import { Link } from "react-router-dom";
import './Sidebar.style.scss';

function Sidebar({auth, closeNav}) {


    useEffect(() => {
        console.log("calling....")
    })

    return (
        <div id="sidebar" className="sidenavBackground" onClick={closeNav}>
            <div className="sidenav">
                <div className="sidenav__profile">
                    <h3>User</h3>
                    <div className="sidenav__profile__profilePicture">
                        <img alt="" src="https://via.placeholder.com/150"/>
                        <div>
                            <h4>{auth.fullName}</h4>
                            <h5>{auth.email}</h5>
                        </div>
                    </div>
                </div>
                <div className="sidenav__menulist">
                    <a href="?">About</a>
                    <a href="?">Services</a>
                    <a href="?">Clients</a>
                    <a href="?">Contact</a>
                </div>
            </div>
        </div>
    )
}

export default Sidebar;